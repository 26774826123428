<div id="carouselExampleCaptions" class="carousel slide carousel-fade homeMargin" data-bs-ride="carousel">
  <ol class="carousel-indicators">
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></li>
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></li>
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"></li>
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3"></li>
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4"></li>
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5"></li>
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6"></li>
  </ol>
  <div class="carousel-inner">
     <div class="carousel-item active">
      <img src="https://quesera.s3.amazonaws.com/pictures/Web-125.jpg" class="d-block w-100" atl="QUE SERA FARM">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img src="https://quesera.s3.amazonaws.com/pictures/Arena-Night.jpg" class="d-block w-100" atl="QUE SERA FARM">
      <div class="carousel-caption d-none d-md-block">
         <!--
        <h5>First slide label</h5>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        -->
      </div>
    </div>
    <div class="carousel-item">
      <img src="https://quesera.s3.amazonaws.com/pictures/Covered-Arena.jpg" class="d-block w-100" atl="QUE SERA FARM">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img src="https://quesera.s3.amazonaws.com/pictures/Arena-and-Grandstand.jpg" class="d-block w-100" atl="QUE SERA FARM">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img src="https://quesera.s3.amazonaws.com/pictures/Tie-Ups.jpg" class="d-block w-100" atl="QUE SERA FARM">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img src="https://quesera.s3.amazonaws.com/pictures/Jumps-01.jpg" class="d-block w-100" atl="QUE SERA FARM">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img src="https://quesera.s3.amazonaws.com/pictures/Jumps-04.jpg" class="d-block w-100" atl="QUE SERA FARM">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </a>
</div>

<div id="intro" class="index-1 intro-full-next pt-6">
  <div class="container mt-2" id="intro-next">
    <section class="mb-4">
      <h1 class="color-primary text-center">Welcome to Que Sera Farm</h1>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 mb-2 text-justify lead">
          <p>Welcome to Que Sera Farm – a premium equestrian centre offering discerning owners and riders a first-class facility with professional care and services.  We offer agistment in large and safe stabling with rubber floors, paddocks with shelters, undercover wash bays with hot and cold water and saddling areas, olympic sized indoor arena with superb European surface and LED lighting, undercover round yard, horse walker, a large secure parking area and our very own on-site tack store, 'Equistore'.  Recently completed is a new outdoor arena, jumping arena, an Equitation obstacle course and a trail ride. We host numerous clinics, dressage, equitation, liberty, jumping etc. and welcome visitors. Bookings are recommended.</p>
          <p>Overnight camping facilities are available. We do not provide horses for hire. Que Sera is a working farm with cattle and cultivation so visiting dogs must be kept under strict control and on a leash at all times.</p>
          <p>Que Sera Farm is located in the tranquil Biddaddaba Valley in the Gold Coast Hinterland, comprising 378 acres of prime farm land with a combination of creek flats, rolling slopes, plateau rich soils and pasture.</p>
          <p>Que Sera and Equistore are the registered trading names of Gemini Equestrian and Livestock Services Pty Ltd (“GELS”). Kay Sutherland, the owner, also breeds a limited number of high quality horses available for sale.</p>
          <p>We look forward to welcoming you…</p>
        </div>
      </div>
    </section>
  </div> 
</div>
<!--
<div id="intro" class="index-1 intro-full-next">
  <div class="container mt-2" id="intro-next">
    <section class="mb-4">
      <h1 class="color-primary text-center">Gift Vouchers</h1>
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 mb-2 text-justify lead">
          <a href="https://equistore.com.au/gift-voucher-2.html" target="_blank" class="img-thumbnail withripple">
              <div class="thumbnail-container">
                  <img src="https://quesera.s3.amazonaws.com/gift_voucher.jpg" alt="Gift Voucher 20$" class="img-fluid">
              </div>
          </a>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 mb-2 text-justify lead">
          <a href="https://equistore.com.au/gift-voucher-1.html" target="_blank" class="img-thumbnail withripple">
              <div class="thumbnail-container">
                  <img src="https://quesera.s3.amazonaws.com/gift_voucher-50.jpg" alt="Gift Voucher 50$" class="img-fluid">
              </div>
          </a>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 mb-2 text-justify lead">
          <a href="https://equistore.com.au/gift-voucher-2.html" target="_blank" class="img-thumbnail withripple">
              <div class="thumbnail-container">
                  <img src="https://quesera.s3.amazonaws.com/gift_voucher-100.jpg" alt="Gift Voucher 100$" class="img-fluid">
              </div>
          </a>
        </div>
      </div>
    </section>
  </div> 
</div>
-->
<div class="container">
  <div class="card mt-2">
    <div class="card-body card-body-big">
      <h2 class="text-center fw-500">About Us</h2>
     <div class="row mt-4">
        <div class="col-lg-6 order-lg-1 text-justify">
          <p class="dropcaps">Since April 2007, when the farm was purchased by Kay Sutherland, there has been a hive of activity. Todd Cunningham of Equestrian Land Development Pty Ltd. installed our Arena and Round Yard. Both are fenced in steel and have gates, and the Round Yard has a rubber skirt to ensure that horses do not hurt themselves while being lunged or broken in. This facility has been roofed.</p>
          <p>The arena has been roofed and boasts LED lighting. There is a Grandstand at the far end of the arena, again roofed. A new covered Wash Bay area has been established and the floor concreted. Tough rubber matting is on the concrete leading to each bay and in the bays themselves. Thus providing safety and comfort for each horse.</p>
          <p>Our paddocks are fenced with steel and protected by electric fencing. A very large energizer has been installed to ensure that all paddocks have adequate power to the fencing. A 600 volt back-up battery is attached to the system which will start immediately should a power cut occur.</p>
          <p>We have a very large dam and several bores which gives us an independent water supply. We currently have 10 stables plus a 4-stable complex with individual paddocks. Also a foaling down stable. With its own paddock which means we can put a mare there to foal a month before she is due and she will have ample room to exercise and to get used to the stable.</p>
          <p>We have a car park below the arena with ample parking for floats and trucks. We also have 9 grassed Day Yards at the far end of the car park. Our Horse Walker holds four horses. Each horse is segregated by hanging barriers.</p>
          <p>Our aim is to provide a boutique, five star, operation to provide a comprehensive service covering a  number of equestrian disciplines. Our agreements reflect the situation in law but each contract is tailor-made to suit the requirements of our clients.</p>
          <p>We look forward to hearing from you and please do not hesitate to ask for any specific equestrian needs that you may have. Please see Services and Facilities for further details.</p>
          <p>Que Sera Farm is run by Kay Sutherland with an excellent support team. </p>
          <p>Please look at the Map which shows the exact farm location. Situated 10.5 km from the Nerang\Beaudesert Road. Few vehicles venture past our property and it is a beautiful and peaceful place to be, just perfect for horses and riders. </p>
        </div>
        <div class="col-lg-6 order-lg-2 text-justify">
          <h3 class="color-primary text-center">History of Que Sera</h3>
            <p class="dropcaps">Welcome to Gemini Equestrian & Livestock Services Pty Ltd operating as Que Sera Farm. Que Sera Farm is located deep in the beautiful Biddaddaba Valley with frontage to Biddaddaba Creek. It comprises 378 acres of prime farm land. We have a combination of creek flats, rolling slopes, plateau rich soils and pasture. A mountain range frames our property.</p>
            <p>Que Sera Farm is run by Kay Sutherland with an excellent support team. Our staff are well versed in all aspects of horsemanship and have good understanding of and relationship with horses.</p>
            <p>Please look at our Map which shows the exact farm location. Situated 10.5 km from the Nerang\Beaudesert Road few vehicles venture past our property and it is a beautiful and peaceful place to be. Just perfect for horses.</p>
            <div class="ms-thumbnail-container">
              <figure class="ms-thumbnail ms-thumbnail-diagonal">
                <img src="https://quesera.s3.amazonaws.com/pictures/Ariel_view.jpg" alt="" class="img-fluid">
                <figcaption class="ms-thumbnail-caption text-center">
                  <div class="ms-thumbnail-caption-content">
                    <h3 class="ms-thumbnail-caption-title">Go to our photo gallery</h3>
                    <a routerLink="/gallery" routerLinkActive="active" class="btn btn-white">View gallery</a>
                  </div>
                </figcaption>
              </figure>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<div class="container">
  <div class="row mb-6">
    <div class="col-lg-8 col-xl-8 order-lg-1">
      <ul class="list-unstyled hand-list">
        <li class="animated fadeInLeft animation-delay-2">
          <h2 class="color-primary right-line">Equistore.com.au</h2>
          <p>In 2010 we commenced importing high quality equestrian items such as horse rugs, sheepskin numnahs, whips, padded halters, saddles pads and many other products that have been sourced by us.</p>
          <p>We trade under the “Equistore” trademark. Many of our products are made exclusively for us.</p>
          <p>We have a Trade Stand which we use to exhibit our range of goods at a number of competition venues as well as a farm shop where people can come and browse.</p>
          <p>Visitors to the farm are entitled to a 10% discount on purchases over $100.</p>
          <p>Please look at our online store at <a href="https://equistore.com.au" target="_blank">www.equistore.com.au</a></p>
        </li>
      </ul>
    </div>
    <div class="col-lg-4 col-xl-4 order-lg-2">
      <a href="https://equistore.com.au" target="_blank">
        <img title="Equistore.com.au" class="img-fluid animated zoomInDown animation-delay-3" src="https://quesera.s3.amazonaws.com/browser-pack.png">
      </a>
    </div>
  </div>
</div>
-->
<app-map-frame></app-map-frame>
