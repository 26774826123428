<div class="ms-slidebar sb-slidebar sb-left sb-style-overlay" id="ms-slidebar">
  <div class="sb-slidebar-container">
    <header class="ms-slidebar-header">
      <div class="ms-slidebar-login">
        <a href="https://equistore.com.au" target="_blank" class="withripple" style="text-decoration: none; color: #000; "><b>Visit Equistore.com.au</b></a>
      </div>
      <div class="ms-slidebar-title">
        <div class="ms-slidebar-t">
          <img src="https://quesera.s3.amazonaws.com/logo_ribbon_250_black.png" alt="QueSera Farm" style="height: 35px;">
        </div>
      </div>
    </header>
    <ul class="ms-slidebar-menu" id="slidebar-menu" role="tablist" aria-multiselectable="true">
      <li>
        <a class="link" routerLink="/" routerLinkActive="active">Home</a>
      </li>
      <li class="card" role="tab" id="sch3">
        <a class="collapsed" role="button" data-toggle="collapse" href="#sc3" aria-expanded="false" aria-controls="sc3">Facilities</a>
        <ul id="sc3" class="card-collapse collapse" role="tabpanel" aria-labelledby="sch3" data-parent="#slidebar-menu">
            <li><a routerLink="/Facilities" routerLinkActive="active">Our Facilities</a></li>
            <li><a routerLink="/FacilityStables" routerLinkActive="active">Safe Paddocks & Stables</a></li>
            <li><a routerLink="/FacilityIndoorArena" routerLinkActive="active">Indoor Arena</a></li>
            <li><a routerLink="/FacilityOutdoorArena" routerLinkActive="active">Outdoor Arena</a></li>
        </ul>
      </li>
      <li class="card" role="tab" id="sch4">
        <a class="collapsed" role="button" data-toggle="collapse" href="#sc4" aria-expanded="false" aria-controls="sc4">Services</a>
        <ul id="sc4" class="card-collapse collapse" role="tabpanel" aria-labelledby="sch4" data-parent="#slidebar-menu">
            <!--<li><a routerLink="/ServiceAgistment" routerLinkActive="active">Agistment Regime</a></li>-->
            <li><a routerLink="/ServiceArenas" routerLinkActive="active">Arena Hire</a></li>
            <li><a routerLink="/ServiceEquitation" routerLinkActive="active">Equitation</a></li>
            <li><a routerLink="/ServiceJumping" routerLinkActive="active">Jumping</a></li>
            <li><a routerLink="/ServiceTrailRiding" routerLinkActive="active">Trail Riding</a></li>
            <li><a routerLink="/Clinics" routerLinkActive="active">Clinics</a></li>
        </ul>
      </li>
      <li>
        <a class="link" routerLink="/prices" routerLinkActive="active">Prices</a>
      </li>
      <li>
        <a class="link" routerLink="/horses-for-sale" routerLinkActive="active">Horses for sale</a>
      </li>
      <li>
        <a class="link" routerLink="/RacehorseSpelling" routerLinkActive="active">Racehorse Spelling</a>
      </li>
      <!--
      <li>
        <a class="link" href="https://equistore.com.au" target="_blank">Shop</a>
      </li>
      -->
      <li>
        <a class="link" routerLink="/gallery" routerLinkActive="active">Gallery</a>
      </li>
      <li class="card" role="tab" id="sch5">
        <a class="collapsed" role="button" data-toggle="collapse" href="#sc5" aria-expanded="false" aria-controls="sc5">Gallery</a>
        <ul id="sc5" class="card-collapse collapse" role="tabpanel" aria-labelledby="sch4" data-parent="#slidebar-menu">
            <li><a routerLink="/gallery-view" routerLinkActive="active">360 Tour View</a></li>
            <li><a routerLink="/gallery-videos" routerLinkActive="active">Video Gallery</a></li>
            <li><a routerLink="/gallery" routerLinkActive="active">Photo Gallery</a></li>
            <li><a routerLink="/gallery-events" routerLinkActive="active">Events</a></li>
        </ul>
      </li>
      <!--
      <li>
        <a class="link" routerLink="/team" routerLinkActive="active">Team</a>
      </li>
      -->
      <li>
        <a class="link" href="https://quesera.com.au/booking/" routerLinkActive="active">Booking</a>
      </li>
      <li>
        <a class="link" routerLink="/contact" routerLinkActive="active">Contact us</a>
      </li>
    </ul>
    <div class="ms-slidebar-social ms-slidebar-block">
      <h4 class="ms-slidebar-block-title">Social Links</h4>
      <div class="ms-slidebar-social">
        <a href="https://www.facebook.com/pg/queserafarm/" target="_blank" class="btn-circle btn-circle-raised btn-facebook"><i class="fa fa-facebook"></i>
          <div class="ripple-container"></div>
        </a>
        <a href="https://www.facebook.com/queserafarm/" target="_blank" class="btn-circle btn-circle-raised btn-instagram"><i class="fa fa-instagram"></i>
          <div class="ripple-container"></div>
        </a>
      </div>
    </div>
  </div>
</div>