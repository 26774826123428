    <div class="container">
          <div class="row">
            <div class="col-lg mb-2 text-center">
                <h1 class="color-primary text-center">"Que Sera Farm" - 369* Acres - Biddaddaba Valley, Queensland</h1>
            </div>
          </div>
          <div class="row">
			<div class="col-lg mb-2 text-justify">
				<br>
				<hr>
				<br>
				<p class="bigFont">Located in the tranquil Biddaddaba Valley in Queensland, Que-Sera is the perfect solution for equestrian lovers, breeders and trainers looking for a significant rural estate just a 45 minute drive from Brisbane or the Gold Coast.</p>
				<p class="bigFont">The property features four separate houses, prime grazing land, abundant water and excellent infrastructure on two titles. The property will be sold partly as a going concern and the vendor may consider selling the titles individually.</p>
				<p class="bigFont">The farm rises from fertile creek flats to soft rolling hillsides surrounded by a timbered ridgeline. The location is stunning. Que Sera has been in business for over fifteen years and is well known in the equestrian community. <br><a href="www.quesera.com.au">www.quesera.com.au</a></p>
				<br>
				<p class="bigFont"><b>Que Sera Farm title 1 (QS1) Includes:</b></p>
				<ul class="bigFont">
				  <li>101* Hectares or 249* acres</li>
				  <li>Traditional Queenslander house - 3 bed, 1 bath</li>
				  <li>Hilltop villa - 4 bed, 2 bath</li>
				  <li>Covered Olympic sized dressage arena (Geo-Pat surface, sprinklers and LED lighting)</li>
				  <li>Covered round yard (sand surface - 18m diameter)</li>
				  <li>No 1 Stable (7 stalls, 4 covered wash bays and 2 tack rooms)</li>
				  <li>No 2 Stable (3 stalls and crush)</li>
				  <li>No 3 Stable (3 stalls and tack/feed room)</li>
				  <li>Foaling stable and paddock</li>
				  <li>Covered day yards (9 yards with wash bay - 36m* x 4m*)</li>
				  <li>Horse walker (4 horses 20m* diameter) can be expanded to 8 horses</li>
				  <li>Business centre (2 storey insulated barn offering storage, offices and amenities)</li>
				  <li>Covered shavings store</li>
				  <li>Machinery shed and workshop (20m* x 9m*)</li>
				  <li>44* Steel fenced paddocks with shelters, water and electric fencing</li>
				  <li>Larger paddocks on the hillside</li>
				  <li>Outdoor dressage arena</li>
				  <li>Outdoor showjumping course</li>
				  <li>Equitation obstacle course</li>
				  <li>Cattle yards</li>
				  <li>Ample parking areas for trucks and trailers</li>
				  <li>Camping areas</li>
				  <li>Large dam and high capacity bore</li>
				</ul>

				<p class="bigFont"><b>Que Sera Farm title 2 (QS2) includes:</b></p>
				<ul class="bigFont">
				  <li>48.5* Hectares or 120* acres</li>
				  <li>Que Sera spacious main residence - 5 bed, 3 bath, study, family room and pool</li>
				  <li>Second residence - 4 bed, 2 bath</li>
				  <li>10* Covered stables (32m* x 12m*)</li>
				  <li>Machinery shed (24m* x 12m*)</li>
				  <li>2 Dams and bore</li>
				  <li>Paddocks and cattle yards</li>
				  <li>Numerous paddocks with shelters, water and electric fencing</li>
				</ul>
				<br>
				<p class="bigFont">Que Sera Farm is currently producing income from house rentals, horse agistment, equine facility rentals, horse training, breeding, camping and events. A cattle herd of up to 50* breeders has been raised on the farm together with hay cultivation in two large paddocks. There are plans to significantly increase the business of racehorse training and spelling. This is rare chance to acquire a premier equestrian property on the doorstep of Brisbane and the Gold Coast.</p>
				<p class="bigFont">Asking price is $12 million to be split approximately 75:25 between the land and the assets and goodwill of the business. Gemini Equestrian & Livestock Services Pty Ltd – the operating company will not be sold.</p>
				<p class="bigFont">Qualified buyers should contact Mrs Kay Sutherland directly to arrange viewing on 0429 810668 or ks@gemini.com.au.</p>
				<p class="bigFont">* approximately</p>
			</div>
          </div>
        </div>


<div class="container">
	<div class="row">
		<div class="col-md-12">
			<div class="card">
			  <video controls>
			    <source src="https://quesera.s3.amazonaws.com/QueSera_Video.mp4#t=0.9" type="video/mp4" />
			  </video>
			</div>
		</div>
	</div>
</div>

<div class="container">
          <div id="carousel-example-generic" class="ms-carousel ms-carousel-thumb carousel slide" data-ride="carousel">
              <div class="card card-relative">
                  <div class="carousel-inner" role="listbox">
						<!-- Carousel Items -->
						<div class="carousel-item active">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/aerial-view-arena-barn-stables.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/aerial-view-4.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/Barn.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/car-park-1.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/car-park-2.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/covered-arena-interior-night.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/covered-arena-night.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/equitage-obstacle-course.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/foaling-stable.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/horse-walker-stables-barn.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/horse-walker.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/main-house-family-dining.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/main-house-from-above.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/main-house-garage.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/main-house-kitchen.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/main-house-lounge.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/main-house-main-guest-bedroom.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/main-house-master-bedroom.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/Paddocks.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/queenslander-1.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/queenslander-interior-1.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/queenslander-interior-2.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/round-yard-interior.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/stable-block.jpg" alt="QUE SERA FARM">
						</div>
						<div class="carousel-item">
						    <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/sale/sunset-aerial-view.jpg" alt="QUE SERA FARM">
						</div>

                  </div>
                  <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary left carousel-control-prev" role="button" data-slide="prev"><i class="zmdi zmdi-chevron-left"></i></a>
                  <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary right carousel-control-next" role="button" data-slide="next"><i class="zmdi zmdi-chevron-right"></i></a>
              </div>
              <ol class="carousel-indicators carousel-indicators-tumbs carousel-indicators-tumbs-outside">
			    <li data-target="#carousel-example-generic" data-slide-to="0" class="active">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/aerial-view-arena-barn-stables_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="1">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/aerial-view-4_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="2">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/Barn_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="3">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/car-park-1_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="4">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/car-park-2_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="5">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/covered-arena-interior-night_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="6">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/covered-arena-night_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="7">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/equitage-obstacle-course_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="8">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/foaling-stable_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="9">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/horse-walker-stables-barn_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="10">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/horse-walker_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="11">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/main-house-family-dining_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="12">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/main-house-from-above_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="13">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/main-house-garage_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="14">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/main-house-kitchen_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="15">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/main-house-lounge_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="16">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/main-house-main-guest-bedroom_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="17">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/main-house-master-bedroom_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="18">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/Paddocks_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="19">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/queenslander-1_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="20">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/queenslander-interior-1_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="21">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/queenslander-interior-2_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="22">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/round-yard-interior_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="23">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/stable-block_thumb.jpg" alt="">
			    </li>
			    <li data-target="#carousel-example-generic" data-slide-to="24">
			        <img class="" src="https://quesera.s3.amazonaws.com/sale/sunset-aerial-view_thumb.jpg" alt="">
			    </li>
              </ol>
          </div>

        </div>


<!--
<div class="intro-full color-white " id="home">
  <video id="intro-video" class="video-fluid embed-responsive embed-responsive-16by9 z-depth-1-half" playsinline autoplay loop muted>
    <source class="embed-responsive-item" src="https://quesera.s3.amazonaws.com/quesera_drone.mp4" type="video/mp4" />
  </video>

  <div class="intro-full-content index-1">
    <div class="container text-center">
      <img [src]="home.logo" alt="QueSera Farm">
    </div>
  </div>
</div>
-->