import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { HomeComponent } from './home/home.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { FooterComponent } from './footer/footer.component';
import { ModalsComponent } from './modals/modals.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ContactComponent } from './contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
//import { ServiceAgistmentComponent } from './service-agistment/service-agistment.component';
import { ServiceArenasComponent } from './service-arenas/service-arenas.component';
import { ServiceEquitationComponent } from './service-equitation/service-equitation.component';
import { ServiceJumpingComponent } from './service-jumping/service-jumping.component';
import { ServiceTrailRidingComponent } from './service-trail-riding/service-trail-riding.component';
import { ClinicsComponent } from './clinics/clinics.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { MapFrameComponent } from './map-frame/map-frame.component';

import {GoogleAnalyticsService} from './google-analytics.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HorsesForSaleComponent } from './horses-for-sale/horses-for-sale.component';
import { FacilityStablesComponent } from './facility-stables/facility-stables.component';
import { FacilityIndoorArenaComponent } from './facility-indoor-arena/facility-indoor-arena.component';
import { FacilityOutdoorArenaComponent } from './facility-outdoor-arena/facility-outdoor-arena.component';
import { FacilityJumpingComponent } from './facility-jumping/facility-jumping.component';
import { FacilitiesComponent } from './facilities/facilities.component';
import { PricesComponent } from './prices/prices.component';
import { GalleryViewComponent } from './gallery-view/gallery-view.component';
import { GalleryVideosComponent } from './gallery-videos/gallery-videos.component';
import { TeamComponent } from './team/team.component';
import { SocialComponent } from './social/social.component';
import { BookingComponent } from './booking/booking.component';
import { GalleryEventsComponent } from './gallery-events/gallery-events.component';
import { SafePipe } from './Pipes/safe.pipe';
import { RacehorseSpellingComponent } from './racehorse-spelling/racehorse-spelling.component';
import { SaleComponent } from './sale/sale.component';
@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    SideMenuComponent,
    FooterComponent,
    ModalsComponent,
    GalleryComponent,
    ContactComponent,
    //ServiceAgistmentComponent,
    ServiceArenasComponent,
    ServiceEquitationComponent,
    ServiceJumpingComponent,
    ServiceTrailRidingComponent,
    ClinicsComponent,
    ContactModalComponent,
    MapFrameComponent,
    HorsesForSaleComponent,
    FacilityStablesComponent,
    FacilityIndoorArenaComponent,
    FacilityOutdoorArenaComponent,
    FacilityJumpingComponent,
    FacilitiesComponent,
    PricesComponent,
    GalleryViewComponent,
    GalleryVideosComponent,
    TeamComponent,
    SocialComponent,
    BookingComponent,
    GalleryEventsComponent,
    SafePipe,
    RacehorseSpellingComponent,
    SaleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [GoogleAnalyticsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
